<template>
<!-- 活动页面 -->
  <div id="activity">
    <div class="container">
      <div class="mainTitle">
        北美CS求职<span style="margin-left: 16px">职来有你</span>
        <span class="blue" style="margin-left: 16px">免费课程</span>
        <div class="subtitle">LeetCode 千题视频讲解，北美求职刷题必修课</div>
      </div>
      <div class="activities">
        <div class="activity">
          <div class="step">1</div>
          <div class="rightText">
            <div>新用户</div>
            <div>New User</div>
          </div>
        </div>
        <div class="activity">
          <div class="step">2</div>
          <div class="rightText">
            <div>首次购</div>
            <div>First Purchase</div>
          </div>
        </div>
        <div class="activity">
          <div class="step">3</div>
          <div class="rightText">
            <div>班课购</div>
            <div>Buy Course</div>
          </div>
        </div>
        <div class="activity">
          <div class="step">4</div>
          <div class="rightText">
            <div>拉新优惠</div>
            <div>Bring Newcomer</div>
          </div>
        </div>
      </div>
      <div class="main-container">
        <div>
          <span class="title">活动一 （新用户）</span>
        </div>
        <div class="content">
          CSON网站新用户（未购买过任何课程），获得《LeetCode
          1-300题视频讲解》<span class="blue">3天免费课程</span>
        </div>
        <div>
          <span class="title">活动二 （首次购）</span>
        </div>
        <div class="content">
          CSON网站 首次购买《LeetCode 千题视频讲解》，减免
          <span class="blue">$20</span>
          <div class="tip">
            特别说明：活动二实行先付款后返现形式，付款完成课程后，我们会进行返现操作。
          </div>
        </div>
        <div>
          <span class="title">活动三 （班课购）</span>
        </div>
        <div class="content">
          对于所有想要报名班课的同学（《算法班》《求职班》《旗舰班》）
          <br />
          <div style="margin-top: 16px">
            获得<span class="blue">2周的完整体验</span>，期间由Edward老师<span
              class="blue"
              >每周电话监督</span
            >，和已报名学员无任何差别
          </div>
        </div>
        <div>
          <span class="title">参与方式</span>
        </div>
        <div class="content" style="margin-bottom: 0">
          完成以下两个内容即可获得上述三个活动任意一次。

          <br />
          <div style="margin-top: 16px">
            1. 添加客服微信号，转发海报到任意100人以上2个北美CS微信群 +
            朋友圈，五分钟后截图，发送客服
          </div>
          <div style="margin-top: 16px">
            2. 本网站注册帐号，发布任意2条动态，内容关于北美求职即可
          </div>
        </div>
        <div class="kefu-code">
          <div style="margin-bottom: 12px">扫码添加客服</div>
          <img src="@/assets/img/newCode.jpg" alt="" />
        </div>
      </div>
      <div class="sub-container">
        <div>
          <span class="title">活动四（拉新优惠）</span>
        </div>
        <div class="content" style="margin-bottom: 0">
          凡是推荐其他用户<span class="blue">购买</span
          >网站任意课程，即可获得价值<span class="blue">$79</span>的《LeetCode
          千题视频讲解》<span class="blue">1-600题一个月免费课程</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Activity0929",
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss">
#activity {
  position: relative;
  background: url(../../assets/img/activityBg.png) no-repeat;
  background-size: 100% 100%;
  #background {
    vertical-align: top;
  }
  .container {
    width: 1200px;
    top: 0;
    left: 50%;
    margin: 0 auto;
    padding-top: 54px;
    padding-bottom: 54px;
  }
}
.blue {
  color: rgba(10, 122, 255, 1);
}
.mainTitle {
  font-size: 32px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ffffff;
  line-height: 32px;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 30px;
  .subtitle {
    font-size: 22px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
    margin-top: 14px;
  }
}
.activities {
  margin-top: 68px;
  display: flex;
  justify-content: space-between;
  .activity {
    width: 260px;
    height: 144px;
    background: rgba(0, 0, 0, 0.45);
    border-radius: 12px;
    .step {
      font-size: 72px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #0a7aff;
      line-height: 144px;
      margin-left: 48px;
      display: inline-block;
    }
    .rightText {
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      line-height: 32px;
      padding: 40px 0;
      padding-left: 14px;
      display: inline-block;
      vertical-align: top;
    }
  }
}
.main-container,
.sub-container {
  background: rgba(0, 0, 0, 0.45);
  border-radius: 12px;
  width: 100%;
  margin-top: 30px;
  padding: 50px;
  position: relative;
  .title {
    font-size: 20px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    line-height: 20px;
    position: relative;
    z-index: 9;
    &::after {
      position: absolute;
      left: -5%;
      bottom: -2px;
      width: 110%;
      height: 10px;
      background: rgba(0, 132, 255, 1);
      border-radius: 4px;
      content: "";
      z-index: -1;
    }
  }
  .content {
    margin-top: 24px;
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    line-height: 24px;
    margin-bottom: 60px;
    .tip {
      margin-top: 16px;
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #e59f3c;
      line-height: 24px;
    }
  }
  .kefu-code {
    position: absolute;
    right: 132px;
    bottom: 40px;
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
    line-height: 27px;
    letter-spacing: 1px;
    img {
      width: 132px;
      height: 132px;
      vertical-align: top;
    }
  }
}
</style>